export const bmiCalculator = (height: number, weight: number) => {
  let isIdeal = false;
  const bmi = weight / ((height / 100) * (height / 100));
  console.log(bmi);
  if (bmi < 18.5) {
    isIdeal = false;
  } else if (bmi > 20 && bmi < 29.9) {
    isIdeal = true;
  } else {
    isIdeal = false;
  }

  return isIdeal;
};
